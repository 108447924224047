// import { Box, styled, Typography } from "@mui/material";
// import LinearProgress, {
//   linearProgressClasses,
// } from "@mui/material/LinearProgress";
// import { useEffect, useState } from "react";
// import axios from "axios";

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 8,
//   // borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor:
//       theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     // borderRadius: 5,
//     backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
//   },
// }));

// const Credits = ({ userEmail }) => {
//   const [aiCreditData, setAiCreditData] = useState({});
//   const [leadCreditData, setLeadCreditData] = useState({});
//   const [validationCreditData, setValidationCreditData] = useState({});
//   const [bdrCreditData, setBdrCreditData] = useState({});
//   const [emailCreditData, setEmailCreditData] = useState({});
//   const used = 900;
//   const totalCredits = 1200;
//   const totalUsedCredits = used / totalCredits;
//   const type = ["ai", "lead", "validation", "bdr", "email"];

//   const validateData = (data, creditType) => {
//     if (creditType === "validation") {
//       setValidationCreditData(data);
//     } else if (creditType === "ai") {
//       setAiCreditData(data);
//     } else if (creditType === "lead") {
//       setLeadCreditData(data);
//     } else if (creditType === "bdr") {
//       setBdrCreditData(data);
//     } else if (creditType === "email") {
//       setEmailCreditData(data);
//     }
//   };

//   const getCredits = async () => {
//     type.map(async (creditType) => {
//       // axios.get(`https://creditmanager.b2brocket.ai/api/v1/main/credits/?email=${userEmail}&type=${creditType}`)
//       axios
//         .get(
//           `https://creditmanager.b2brocket.ai/api/v1/main/credits/?email=${userEmail}`
//         )
//         .then((res) => {
//           validateData(res, creditType);
//         })
//         .catch((err) => {
//           validateData(err, creditType);
//         });
//     });
//   };
//   console.log(userEmail);

//   useEffect(() => {
//     getCredits();
//   }, [userEmail]);

//   const getValue = (total, remaining) => {
//     let value = 0;
//     if (total === remaining) {
//       value = (remaining / total) * 100;
//     } else {
//       value = ((total - remaining) / total) * 100;
//     }
//     return value;
//   };

//   return (
//     <>
//       <Box>
//         <Typography
//           sx={{
//             fontWeight: "700",
//             fontSize: "16px",
//             color: "#050C46",
//             marginBottom: "10px",
//           }}
//         >
//           Used AI Writer Credits
//         </Typography>
//         {aiCreditData.status === 200 ? (
//           // {const totalCredits= (aiCreditData.data.credits.remaining / aiCreditData.data.credits.total) *100}
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               {aiCreditData.data.credits.remaining === null
//                 ? 0
//                 : aiCreditData.data.credits.remaining}{" "}
//               /{" "}
//               {aiCreditData.data.credits.total === null
//                 ? 0
//                 : aiCreditData.data.credits.total}
//             </Typography>
//             <BorderLinearProgress
//               variant="determinate"
//               value={
//                 aiCreditData.data.credits.remaining === null
//                   ? 0
//                   : getValue(
//                       aiCreditData.data.credits.total,
//                       aiCreditData.data.credits.remaining
//                     )
//               }
//             />
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               Your credits have been exhausted please buy more.
//             </Typography>
//           </Box>
//         )}
//       </Box>
//       <Box sx={{ marginX: "10px" }}>
//         <Typography
//           sx={{
//             fontWeight: "700",
//             fontSize: "16px",
//             color: "#050C46",
//             marginBottom: "10px",
//           }}
//         >
//           Lead
//         </Typography>
//         {leadCreditData.status === 200 ? (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               {leadCreditData.data.credits.remaining === null
//                 ? 0
//                 : leadCreditData.data.credits.remaining}{" "}
//               /{" "}
//               {leadCreditData.data.credits.total === null
//                 ? 0
//                 : leadCreditData.data.credits.total}
//             </Typography>
//             <BorderLinearProgress
//               variant="determinate"
//               value={
//                 leadCreditData.data.credits.remaining === null
//                   ? 0
//                   : getValue(
//                       leadCreditData.data.credits.total,
//                       leadCreditData.data.credits.remaining
//                     )
//               }
//             />
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               Your credits have been exhausted please buy more.
//             </Typography>
//           </Box>
//         )}
//       </Box>

//       <Box sx={{ marginX: "10px" }}>
//         <Typography
//           sx={{
//             fontWeight: "700",
//             fontSize: "16px",
//             color: "#050C46",
//             marginBottom: "10px",
//           }}
//         >
//           Email Validation
//         </Typography>
//         {validationCreditData.status === 200 ? (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               {validationCreditData.data.credits.remaining === null
//                 ? 0
//                 : validationCreditData.data.credits.remaining}{" "}
//               /{" "}
//               {validationCreditData.data.credits.total === null
//                 ? 0
//                 : validationCreditData.data.credits.total}
//             </Typography>
//             <BorderLinearProgress
//               variant="determinate"
//               value={
//                 validationCreditData.data.credits.remaining === null
//                   ? 0
//                   : getValue(
//                       validationCreditData.data.credits.total,
//                       validationCreditData.data.credits.remaining
//                     )
//               }
//             />
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               Credits data not found
//             </Typography>
//           </Box>
//         )}
//       </Box>

//       <Box sx={{ marginX: "10px" }}>
//         <Typography
//           sx={{
//             fontWeight: "700",
//             fontSize: "16px",
//             color: "#050C46",
//             marginBottom: "10px",
//           }}
//         >
//           BDR Validation
//         </Typography>
//         {bdrCreditData.status === 200 ? (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               {bdrCreditData.data.credits.remaining === null
//                 ? 0
//                 : bdrCreditData.data.credits.remaining}{" "}
//               /{" "}
//               {bdrCreditData.data.credits.total === null
//                 ? 0
//                 : bdrCreditData.data.credits.total}
//             </Typography>
//             <BorderLinearProgress
//               variant="determinate"
//               value={
//                 bdrCreditData.data.credits.remaining === null
//                   ? 0
//                   : getValue(
//                       bdrCreditData.data.credits.total,
//                       bdrCreditData.data.credits.remaining
//                     )
//               }
//             />
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               Your credits have been exhausted please buy more.
//             </Typography>
//           </Box>
//         )}
//       </Box>

//       <Box sx={{ marginX: "10px" }}>
//         <Typography
//           sx={{
//             fontWeight: "700",
//             fontSize: "16px",
//             color: "#050C46",
//             marginBottom: "10px",
//           }}
//         >
//           Email Sending
//         </Typography>
//         {emailCreditData.status === 200 ? (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               {emailCreditData.data.credits.remaining === null
//                 ? 0
//                 : emailCreditData.data.credits.remaining}{" "}
//               /{" "}
//               {emailCreditData.data.credits.total === null
//                 ? 0
//                 : emailCreditData.data.credits.total}
//             </Typography>
//             <BorderLinearProgress
//               variant="determinate"
//               value={
//                 emailCreditData.data.credits.remaining === null
//                   ? 0
//                   : getValue(
//                       emailCreditData.data.credits.total,
//                       emailCreditData.data.credits.remaining
//                     )
//               }
//             />
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               padding: "18px 20px 20px 18px",
//               border: "1px solid #EAECF0",
//               borderRadius: "10px",
//               gap: "14px",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 color: "#0A0A0A",
//               }}
//             >
//               Your credits have been exhausted please buy more.
//             </Typography>
//           </Box>
//         )}
//       </Box>
//     </>
//   );
// };
// export default Credits;
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Typography,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import axios from "axios";
import { WarningAmberOutlined } from "@mui/icons-material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Credits = ({ userEmail, userDetails }) => {
  const [creditData, setCreditData] = useState({});
  const [subscriptionData, setSubscriptionData] = useState({});
  const [resetDate, setResetDate] = useState(null);
  const [loading, setLoading] = useState(true);

  const getCredits = async () => {
    try {
      const response = await axios.get(
        `https://creditmanager.b2brocket.ai/api/v1/main/credits/?email=${userEmail}`
      );
      const data = response.data;
      setResetDate(data?.resetsAt);
      const processedData = {};
      data.credits.forEach((credit) => {
        processedData[credit.type.toLowerCase()] = credit;
      });
      setCreditData(processedData);
      setSubscriptionData(data.subscription);
    } catch (error) {
      console.error("Error fetching credit data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCredits();
  }, [userEmail]);

  const getValue = (total, remaining) => {
    if (total === 0) return 0;
    return ((total - remaining) / total) * 100;
  };

  const creditTypes = [
    "ai",
    "ai_bdr",
    "email_sending",
    "email_validation",
    "lead",
  ];

  const subscriptionDetails = {
    startDate: userDetails?.userSubscription?.subscriptions?.base?.startDate
      ? new Date(
          userDetails?.userSubscription?.subscriptions?.base?.startDate
        )?.toDateString()
      : null,
    endDate: userDetails?.userSubscription?.subscriptions?.base?.endDate
      ? new Date(
          userDetails?.userSubscription?.subscriptions?.base?.endDate
        )?.toDateString()
      : null,
    resetsAt: resetDate ? new Date(resetDate)?.toDateString() : null,
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #00AA38",
          borderRadius: 1,
          p: 1,
          px: 2,
          my: 1,
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
          Purchased on: {subscriptionDetails?.startDate || "N/A"}
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
          Resets on: {subscriptionDetails?.resetsAt || "N/A"}
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
          Expires on: {subscriptionDetails?.endDate || "N/A"}
        </Typography>
      </Box>

      {creditTypes.map((type) => {
        const data = creditData[type] || { total: 0, remaining: 0 };
        const thresholdValue = data?.total * 0.9;
        return (
          <Box key={type} sx={{ marginX: "10px", marginBottom: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <Typography
                component="p"
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#050C46",
                }}
              >
                {type.replace("_", " ").toUpperCase()}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "18px 20px 20px 18px",
                border: "1px solid #EAECF0",
                borderRadius: "10px",
                gap: "14px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#0A0A0A",
                  }}
                >
                  {data.consumed || 0} / {data.total || 0}
                </Typography>
                {Boolean(
                  data?.total &&
                    data?.total > 0 &&
                    data?.consumed &&
                    data?.consumed >= thresholdValue
                ) && (
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 0.5,
                      fontSize: "13px",
                      fontWeight: "500",
                      border: "1px solid red",
                      borderRadius: 1,
                      p: 1,
                      color: "red",
                    }}
                  >
                    <WarningAmberOutlined sx={{ fontSize: "16px" }} />
                    You are running low on credits
                  </Typography>
                )}
              </Box>
              <BorderLinearProgress
                variant="determinate"
                value={getValue(data.total, data.remaining)}
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

const SubscriptionInfo = ({ subscriptionData = {} }) => {
  const { startDate, endDate, resetsAt } = subscriptionData;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
        border: "1px solid #EAECF0",
        borderRadius: "8px",
        margin: "20px",
        maxWidth: "600px",
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#0A0A0A",
            marginBottom: "8px",
          }}
        >
          Start Date
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#050C46",
          }}
        >
          {startDate ? startDate : "N/A"}
        </Typography>
      </Box>

      <Box sx={{ flex: 1, textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#0A0A0A",
            marginBottom: "8px",
          }}
        >
          End Date
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#050C46",
          }}
        >
          {endDate ? endDate : "N/A"}
        </Typography>
      </Box>

      <Box sx={{ flex: 1, textAlign: "right" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#0A0A0A",
            marginBottom: "8px",
          }}
        >
          Reset Plan
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#050C46",
          }}
        >
          {resetsAt ? resetsAt : "N/A"}
        </Typography>
      </Box>
    </Box>
  );
};

export default Credits;
